body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#root {
  height: 100vh;
}

@font-face {
  font-family: SansForgetica-Regular;
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/SansForgetica-Regular.0399ab68.otf) format("opentype");
}

@-webkit-keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fade-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(220,14,120,0.4), rgba(250, 0, 150, 0.5));
  height: 100%;
}

.continue-button-enter {
  opacity: 0.01;
}

.continue-button-enter-active {
  opacity: 1;
  transition: opacity 1500ms ease-out;
}

.continue-button-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

.continue-button-exit {
  opacity: 0.01;
}

.title-enter {
  opacity: 0.01;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
}

.title-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: all 1200ms ease-out;
}

.title-exit {
  opacity: 1;
}

.title-exit-active {
  opacity: 0.01;
  transition: opacity 700ms ease-in;
}

.title-exit-done {
  opacity: 0;
}

.progress-enter {
  opacity: 0.01;
}

.progress-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
}

.progress-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-out;
}

.progress-exit {
  opacity: 1;
}

.progress-exit-done {
  opacity: 0;
}

